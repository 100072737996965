/*global angular*/
'use strict'

// Declare modules first
angular.module('pizzahuntSite.controllers', [])
angular.module('pizzahuntSite.config', [])

// Require all the module files
require('./controllers/controllers')
require('./config/config')

function startSite ($state, $q, $urlRouter) {
  // Get the list of all registered states
  $state.get()
    // Find those for pre-loading
    .filter(function (state) {
      return state.preload
    })

    // Create a promise chain that goes to the state, then goes to the next one
    .reduce(function (memo, state) {
      return memo.then(function () {
        return $state.go(state, undefined, { location: false })
      })
    }, $q.when())

    // Now sync the url
    .then(function () {
      $urlRouter.listen()
    })
}

// Set up main app
angular.module('pizzahuntSite', [
  'ui.router',
  'ct.ui.router.extras',
  'ngAnimate',
  'pizzahuntSite.controllers',
  'pizzahuntSite.config',
  'pizzahuntSite.templates',
  'pizzahunt'
]).run(startSite)
