/*global angular*/
'use strict'

/*
 * Same as navCtrl except enabled shopify stuff for buy view
 */
angular.module('pizzahuntSite.controllers').controller('buyCtrl', function ($scope, $state, shopifyManager, textService) {
  var ctrl = $scope.buyCtrl = this

  $scope.$on('$stateChangeSuccess', function (toState) {
    if ($state.includes('main') && !$state.is('main')) {
      ctrl.selected = $state.current.name.split('.').slice(1, 2).pop()

      // Scroll view to top when routed to (but not when routed from)
      document.body.scrollTop = document.documentElement.scrollTop = 0

      // Ensures a loader is shown until products have been fetched from Shopify
      ctrl.hasProducts = false

      // Initialise Shopify Manager
      shopifyManager.init()
        .then(() => {
          ctrl.shopifyManager = shopifyManager
          ctrl.products = ctrl.shopifyManager.products
          ctrl.hasProducts = true

          // Update cart stores for child directives
          let updateCart = (init) => {
            ctrl.cartItems = ctrl.shopifyManager.cartItems
            ctrl.cart = ctrl.shopifyManager.cart
            ctrl.itemCount = ctrl.shopifyManager.cart.lineItemCount
            ctrl.cartPrice = textService.formatAsMoney(ctrl.shopifyManager.cart.subtotal)

            if (init) {
              // Hax to ensure that the cart tab is shown on load when items are in the cart
              $scope.$broadcast('init-cart', {
                itemCount: ctrl.itemCount
              })
            }
          }

          // Hax
          // Init all the vars, especially item count, and signal to broadcast the init event with the item count
          // so that the cart tab is shown correctly on load when the cart has items
          updateCart(true)

          // Update cart stores when add to cart detected
          $scope.$on('add-to-cart', () => {
            updateCart()
            $scope.$broadcast('open-cart')
          })

          // Update cart stores when this event detected
          $scope.$on('update-cart', () => {
            updateCart()
          })
        })
    }
  })
})
