/*global angular*/
'use strict'

/**
 * Set up site app config
 */
angular.module('pizzahuntSite.config').config(function ($stateProvider, $urlRouterProvider,  $locationProvider/*, $stickyStateProvider*/) {
  // For any unmatched url, redirect to /
  $urlRouterProvider.otherwise('/')
  // Don't sync the url till we're ready (i.e. wait till we've preloaded all the marked preload states)
  $urlRouterProvider.deferIntercept()

  // Set up the states
  // Modeled from: https://github.com/christopherthielen/ui-router-extras/blob/gh-pages/example/stickymodal/stickymodaldemo.js
  // Note: the order the states are defined in indicates the order they will be loaded in
  // i.e. see run method in site.js where all states are visited in order on load of the site to anticipate being navigated to
  $stateProvider
    .state('main', {
      abstract: true,
      views: {
        'nav': {
          controller: 'navCtrl',
          templateUrl: 'navigation.html'
        }
      }
    })
    .state('main.info', {
      url: '/',
      views: {
        'opening': {
          templateUrl: 'opening.html'
        },
        'what': {
          templateUrl: 'what.html'
        },
        'who': {
          templateUrl: 'who.html'
        },
        'gallery': {
          templateUrl: 'gallery.html'
        },
        'shop': {
          templateUrl: 'shop.html'
        },
        'video': {
          templateUrl: 'video.html'
        },
        'footer': {
          templateUrl: 'footer.html'
        }
      }
    })
    .state('main.buy', {
      url: '/buy',
      views: {
        'buy': {
          controller: 'buyCtrl',
          templateUrl: 'buy.html'
        }
      }
    })

  // @note for debugging only
  // $stickyStateProvider.enableDebug(true)

  // BEFORE "SPA-GITHUB-PAGES", enabling this mode without a server side
  // component meant we couldn't ever go directly to /page (we could however
  // turn off this mode and go directly to /#/page :)
  // BUT NOW with spa-github-pages we CAN remove the HASH WOOOOOP!!!
  $locationProvider.html5Mode({
    // So we don't need a # before the query params, and routes.
    enabled: true,
    // So we don't need a base element.
    requireBase: true
  });
})
