;(function() {(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/*global angular*/
'use strict';

/**
 * Set up site app config
 */
angular.module('pizzahuntSite.config').config(["$stateProvider", "$urlRouterProvider", "$locationProvider", function ($stateProvider, $urlRouterProvider, $locationProvider /*, $stickyStateProvider*/) {
  // For any unmatched url, redirect to /
  $urlRouterProvider.otherwise('/');
  // Don't sync the url till we're ready (i.e. wait till we've preloaded all the marked preload states)
  $urlRouterProvider.deferIntercept();

  // Set up the states
  // Modeled from: https://github.com/christopherthielen/ui-router-extras/blob/gh-pages/example/stickymodal/stickymodaldemo.js
  // Note: the order the states are defined in indicates the order they will be loaded in
  // i.e. see run method in site.js where all states are visited in order on load of the site to anticipate being navigated to
  $stateProvider.state('main', {
    abstract: true,
    views: {
      'nav': {
        controller: 'navCtrl',
        templateUrl: 'navigation.html'
      }
    }
  }).state('main.info', {
    url: '/',
    views: {
      'opening': {
        templateUrl: 'opening.html'
      },
      'what': {
        templateUrl: 'what.html'
      },
      'who': {
        templateUrl: 'who.html'
      },
      'gallery': {
        templateUrl: 'gallery.html'
      },
      'shop': {
        templateUrl: 'shop.html'
      },
      'video': {
        templateUrl: 'video.html'
      },
      'footer': {
        templateUrl: 'footer.html'
      }
    }
  }).state('main.buy', {
    url: '/buy',
    views: {
      'buy': {
        controller: 'buyCtrl',
        templateUrl: 'buy.html'
      }
    }
  });

  // @note for debugging only
  // $stickyStateProvider.enableDebug(true)

  // BEFORE "SPA-GITHUB-PAGES", enabling this mode without a server side
  // component meant we couldn't ever go directly to /page (we could however
  // turn off this mode and go directly to /#/page :)
  // BUT NOW with spa-github-pages we CAN remove the HASH WOOOOOP!!!
  $locationProvider.html5Mode({
    // So we don't need a # before the query params, and routes.
    enabled: true,
    // So we don't need a base element.
    requireBase: true
  });
}]);

},{}],2:[function(require,module,exports){
/*global angular*/
'use strict';

/*
 * Same as navCtrl except enabled shopify stuff for buy view
 */
angular.module('pizzahuntSite.controllers').controller('buyCtrl', ["$scope", "$state", "shopifyManager", "textService", function ($scope, $state, shopifyManager, textService) {
  var ctrl = $scope.buyCtrl = this;

  $scope.$on('$stateChangeSuccess', function (toState) {
    if ($state.includes('main') && !$state.is('main')) {
      ctrl.selected = $state.current.name.split('.').slice(1, 2).pop();

      // Scroll view to top when routed to (but not when routed from)
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      // Ensures a loader is shown until products have been fetched from Shopify
      ctrl.hasProducts = false;

      // Initialise Shopify Manager
      shopifyManager.init().then(function () {
        ctrl.shopifyManager = shopifyManager;
        ctrl.products = ctrl.shopifyManager.products;
        ctrl.hasProducts = true;

        // Update cart stores for child directives
        var updateCart = function updateCart(init) {
          ctrl.cartItems = ctrl.shopifyManager.cartItems;
          ctrl.cart = ctrl.shopifyManager.cart;
          ctrl.itemCount = ctrl.shopifyManager.cart.lineItemCount;
          ctrl.cartPrice = textService.formatAsMoney(ctrl.shopifyManager.cart.subtotal);

          if (init) {
            // Hax to ensure that the cart tab is shown on load when items are in the cart
            $scope.$broadcast('init-cart', {
              itemCount: ctrl.itemCount
            });
          }
        };

        // Hax
        // Init all the vars, especially item count, and signal to broadcast the init event with the item count
        // so that the cart tab is shown correctly on load when the cart has items
        updateCart(true);

        // Update cart stores when add to cart detected
        $scope.$on('add-to-cart', function () {
          updateCart();
          $scope.$broadcast('open-cart');
        });

        // Update cart stores when this event detected
        $scope.$on('update-cart', function () {
          updateCart();
        });
      });
    }
  });
}]);

},{}],3:[function(require,module,exports){
'use strict';

require('./nav');

require('./buy');

},{"./buy":2,"./nav":4}],4:[function(require,module,exports){
/*global angular*/
'use strict';

var BODY_SCROLLED_CLASS = 'ph-scroll-to--body-scrolled';

/*
 * Enable stick state ui-router-extras functionality
 *
 * Controller from: https://github.com/christopherthielen/ui-router-extras/blob/gh-pages/example/controllers.js
 */
angular.module('pizzahuntSite.controllers').controller('navCtrl', ["$scope", "$state", "$document", function ($scope, $state, $document) {
  var ctrl = $scope.navCtrl = this;
  var $bodyNode = angular.element($document[0].body);

  $scope.$on('$stateChangeSuccess', function (toState) {
    if ($state.includes('main') && !$state.is('main')) {
      ctrl.selected = $state.current.name.split('.').slice(1, 2).pop();

      // Scroll view to top when routed to (but not when routed from)
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    $bodyNode.removeClass(BODY_SCROLLED_CLASS);
  });
}]);

},{}],5:[function(require,module,exports){
/*global angular*/
'use strict';

// Declare modules first
startSite.$inject = ["$state", "$q", "$urlRouter"];
angular.module('pizzahuntSite.controllers', []);
angular.module('pizzahuntSite.config', []);

// Require all the module files
require('./controllers/controllers');
require('./config/config');

function startSite($state, $q, $urlRouter) {
  // Get the list of all registered states
  $state.get()
  // Find those for pre-loading
  .filter(function (state) {
    return state.preload;
  })

  // Create a promise chain that goes to the state, then goes to the next one
  .reduce(function (memo, state) {
    return memo.then(function () {
      return $state.go(state, undefined, { location: false });
    });
  }, $q.when())

  // Now sync the url
  .then(function () {
    $urlRouter.listen();
  });
}

// Set up main app
angular.module('pizzahuntSite', ['ui.router', 'ct.ui.router.extras', 'ngAnimate', 'pizzahuntSite.controllers', 'pizzahuntSite.config', 'pizzahuntSite.templates', 'pizzahunt']).run(startSite);

},{"./config/config":1,"./controllers/controllers":3}]},{},[5])

}());