/*global angular*/
'use strict'

const BODY_SCROLLED_CLASS = 'ph-scroll-to--body-scrolled'

/*
 * Enable stick state ui-router-extras functionality
 *
 * Controller from: https://github.com/christopherthielen/ui-router-extras/blob/gh-pages/example/controllers.js
 */
angular.module('pizzahuntSite.controllers').controller('navCtrl', function ($scope, $state, $document) {
  var ctrl = $scope.navCtrl = this
  let $bodyNode = angular.element($document[0].body)

  $scope.$on('$stateChangeSuccess', function (toState) {
    if ($state.includes('main') && !$state.is('main')) {
      ctrl.selected = $state.current.name.split('.').slice(1, 2).pop()

      // Scroll view to top when routed to (but not when routed from)
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }

    $bodyNode.removeClass(BODY_SCROLLED_CLASS)
  })
})
